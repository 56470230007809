import BaseObserver from "../BaseObserver";
import {Observable} from "rxjs";
import LocationSource from "../../types/general/LocationSource";
import {LocationErrors} from "../../contract/errors/ActionErrors";
import MLatLngInternal from "../../types/general/MLatLngInternal";

class GeoObserver extends BaseObserver<MLatLngInternal>{
    observer: Observable<MLatLngInternal>;

    constructor() {
        super();

        this.observer = Observable.create((subscribe: any) => {

            /*setTimeout( () => {
                subscribe.next({lat: 3, lng: 3, z: 3, source: LocationSource.LIPI, timestamp: this.getTimestamp()});
            }, 1000);*/

            navigator.geolocation.watchPosition(
                position => {
                    const {latitude, longitude, accuracy} = position.coords;
                    const response = {
                        lat: latitude,
                        lng: longitude,
                        z: 0,
                        source: LocationSource.BROWSER,
                        timestamp: this.getTimestamp(),
                        accuracy
                    } as MLatLngInternal;

                    subscribe.next(response);
                },
                error => subscribe.error({error: LocationErrors.GENERIC_LOC_ERROR}),
                { enableHighAccuracy: true }
            )
        });
    }
}

export default GeoObserver