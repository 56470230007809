import BaseImplementation from "../BaseImplementation";
import MapCallback from "../../contract/MapCallback";
import MapMessage from "../../types/map-callback/MapMessage";
import MLatLng from "../../types/general/MLatLng";
import MapReady from "../../types/observer-message-types/MapReady";
import MessangerBridge from "./native-link/MessangerBridge";
import MessageType from "../../types/map-callback/MessageType";
import AllObservers from "../../observers/AllObservers";

class MCallback extends BaseImplementation implements MapCallback{
    exportWindow(): void {

    }

    constructor() {
        super();
    }


    onMapReady(): MapMessage<MapReady> {
        const message : MapMessage<MapReady> = {
            messageType: MessageType.MAP_READY,
            message: {}
        };
        MessangerBridge.sendMessage(message);
        return message;
    }


    afterMapIsReady(): void {
        super.afterMapIsReady();
        this.onMapReady()
    }
}

export default MCallback