import BaseObserver from "./BaseObserver";
import MapReady from "../types/observer-message-types/MapReady";
import {Observable} from "rxjs";
import MapEvent from "../types/observer-message-types/MapEvent";

class LoadObserver extends BaseObserver<MapReady> {

    observer: Observable<MapReady>;

    constructor() {
        super();
        this.observer = this.fromMapEvent(MapEvent.ONLOAD);
    }
}

export default LoadObserver