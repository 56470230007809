import NativeLink from "./NativeLink";
import MapMessage from "../../../types/map-callback/MapMessage";

class IOSMessage implements NativeLink {

    callNativeCode(message: MapMessage<any>): void {
        // @ts-ignore
        window.webkit.messageHandlers.monashapp.postMessage(message);
    }

}

export default IOSMessage