import BaseImplementation from "../BaseImplementation";
import MapRoutes from "../../contract/MapRoutes";
import RouteInformation from "../../types/map-routes/RouteInformation";
import RouteTypes from "./RouteTypes";
import LocationObserver from "../../observers/location-observer/LocationObserver";
import ExecutionStatus from "../../types/general/ExecutionStatus";
import {FitBoundsOptions, LngLat} from "mapbox-gl";
import POIUtils from "../../single/POIUtils";
import asTheCrowFiles from "../../single/DistanceUtils";

type Kilometer = number

class MRoutes extends BaseImplementation implements MapRoutes {

    routeController: any;

    afterMapIsReady(): void {
        super.afterMapIsReady();
        this.routeController = new this.Mazemap.RouteController(this.map);
    }

    exportWindow(): void {
        // @ts-ignore
        window.getRouteInformationToPOI = this.getRouteInformationToPOI.bind(this);
        // @ts-ignore
        window.showRouteToPOIFromCurrentLocation = this.showRouteToPOIFromCurrentLocation.bind(this);
        // @ts-ignore
        window.getPreferredTransitMode = this.getPreferredTransitMode.bind(this);
    }

    async showRouteToPOIFromCurrentLocation(poiId: string, routeType: RouteTypes = RouteTypes.PEDESTRIAN,
                                            fitBoundsOptions: FitBoundsOptions = {}): Promise<ExecutionStatus> {

        /*
        * If it is walkable (same campus) --> default to walk
        * other campus or outside --> Transit mode
        *
        * // TODO: upload data to TripGo
        *
        * */
        const lastLocation = LocationObserver.getLastLocation();
        const failure = {status: false, functionName: "showRouteToPOIFromCurrentLocation"};

        if (!lastLocation) return failure;

        const {lat, lng, z} = lastLocation;
        const src = {
            lngLat: {lat, lng},
            zLevel: z
        };

        const poi = await POIUtils.getPOIInformation(poiId);
        const dest = {poiId: poi.properties.poiId};

        try {
            const geoJson = await this.anyRoute(src, dest);

            // Clear the path
            this.routeController.clear();

            // Draw new path
            this.routeController.setPath(geoJson);

            // Fit the map bounds to the path bounding box
            const bounds = this.Mazemap.Util.Turf.bbox(geoJson);
            this.map.fitBounds(bounds, fitBoundsOptions);

            // padding?: number | mapboxgl.PaddingOptions;
            //         offset?: mapboxgl.PointLike;

            const success = failure;
            success.status = true;
            return success

        } catch (e) {
            return failure
        }
    }

    async anyRoute(source: any, dest: any, routeTypes: RouteTypes = RouteTypes.PEDESTRIAN): Promise<any> {
        return this.Mazemap.Data.getRouteJSON(source, dest, {mode: "TRANSIT"});
    }

    async getRouteInformationToPOI(poiId: string, routeTypes: RouteTypes = RouteTypes.PEDESTRIAN): Promise<RouteInformation> {

        const lastLocation = LocationObserver.getLastLocation();

        if (!lastLocation) return {success: false};

        const {lat, lng, z} = lastLocation;
        const src = {
            lngLat: {lat, lng},
            zLevel: z
        };

        const poi = await POIUtils.getPOIInformation(poiId);
        const dest = {poiId: poi.properties.poiId};

        try {
            const geoJson = await this.anyRoute(src, dest);
            return {
                success: true,
                distance: geoJson.properties.pathMetrics.distanceWalkingMeters,
                time: geoJson.properties.pathMetrics.durationWalkingMinutes
            }

        } catch (e) {
            console.warn(e);
            return {success: false};
        }

    }

    _areDifferentCampus(latLngCurr: LngLat, latLngPoi: LngLat) {

        const THRESHOLD: Kilometer = 3.0;
        const distance = asTheCrowFiles(latLngCurr, latLngPoi);
        return distance > THRESHOLD;
    }

    async getPreferredTransitMode(poiID: string): Promise<RouteTypes | null> {
        const lastLocation = LocationObserver.getLastLocation() as LngLat;

        if (!lastLocation) return null;

        try {
            const poiCoords = await POIUtils.getPOICoordinatesByID(poiID);
            const areDifferentCampus = this._areDifferentCampus(lastLocation, poiCoords);

            if (areDifferentCampus) {
                return RouteTypes.TRANSIT
            } else {
                return RouteTypes.PEDESTRIAN
            }

        } catch (e) {
            return null;
        }
    }

}

export default MRoutes