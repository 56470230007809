import MazemapDefinition from "../types/maze-map-types/Mazemap";
import MonashMap from "../MonashMap";
import AllObservers from "../observers/AllObservers";
import {Observable} from "rxjs";
import MapReady from "../types/observer-message-types/MapReady";

type FunctionQueue = () => void

abstract class BaseImplementation {

    map: MazemapDefinition = MonashMap.map;
    Mazemap = MonashMap.Mazemap;
    loadObserver: Observable<MapReady> = AllObservers.loadObserver;

    constructor() {
        this.exportWindow();

        this._mapIsReady = this._mapIsReady.bind(this);

        this.loadObserver.subscribe(this._mapIsReady);

    }

    // TODO: fix auto exporting and binding of methods in child implementations
    /**
     * @example window.func1 = this.func1.bind(this)
     */
    abstract exportWindow(): void


    _mapIsReady(): void {
        this.afterMapIsReady()
    }


    // region ready function queue

    functionQueue: FunctionQueue[] = [];

    addToReadQueue(func: FunctionQueue): void {
        this.functionQueue.push(func)
    }


    /**
     * @description Override it to perform actions when map is ready and loaded
     */
    afterMapIsReady(): void {
        this.functionQueue.forEach(i => i())
    }

    // endregion
}

export default BaseImplementation

