enum ActionErrors {

    ELEMENT_NOT_FOUND = "The map element does not exist"

}

enum LocationErrors {

    PERMISSION_NOT_ENABLED = "permissionNotEnabled",
    NO_LOCATION_AVAILABLE = "noLocationAvailable",
    POSSIBLE_INACCURACY_IN_RESULT = "possibleInaccuracy",
    GENERIC_LOC_ERROR = "genericLocError",
    LIPI_ERROR = 'lipiError'

}

export {ActionErrors, LocationErrors};
export default ActionErrors