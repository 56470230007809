import BaseImplementation from "../BaseImplementation";
import MapMarkers from "../../contract/MapMarkers";
import MazeMarkerOptions from "../../types/general/MazeMarkerOptions";
import MapHighlightOptions from "../../types/general/MapHighlightOptions";
import MLatLng from "../../types/general/MLatLng";
import POI from "../../types/general/POI";

// TODO: mechanism to remove markers with id
// maybe return an ID

class MMarkers extends BaseImplementation implements MapMarkers {

    highlighter!: any;
    addedMarkers: MapMarkers[] = [];


    afterMapIsReady(): void {
        super.afterMapIsReady();

        // Highlighter is initialized now
        this.highlighter = new this.Mazemap.Highlighter(this.map, {})
    }

    addMarker(latLng: MLatLng, markerOptions: MazeMarkerOptions): void {
        const markerToAdd = new this.Mazemap.MazeMarker(markerOptions)
            .setLngLat(latLng)
            .addTo(this.map);

        this.addedMarkers.push(markerToAdd);

        return markerToAdd
    }

    addPolygon(poiInformation: POI, polyOptions?: MapHighlightOptions): void {
        // TODO the poly options cannot be reset yet
        // apply them
        this.highlighter.highlight(poiInformation)
    }

    addPopup(lng: number, lat: number, title: string): void {

    }

    clearMarkers() {
        // @ts-ignore
        this.addedMarkers.forEach(marker => marker.remove())
    }

    exportWindow(): void {
    }

}

export default MMarkers