
import IOSMessage from "./IOSMessage";
import AndroidMessage from "./AndroidMessage";
import NativeLink from "./NativeLink";
import MapMessage from "../../../types/map-callback/MapMessage";

const iosMessanger: NativeLink = new IOSMessage();
const androidMessanger: NativeLink = new AndroidMessage();

class MessangerBridge {

    static sendMessage(message: MapMessage<any>) {

        //TODO: this might be deprecated in later versions of chrome
        // Look into this if the match will still work
        const ios = navigator.userAgent.match(/iPhone|iPad|iPod/i);
        const android = navigator.userAgent.match(/Android/i);

        try {

            if (ios) {
                iosMessanger.callNativeCode(message)
            } else if (android) {
                androidMessanger.callNativeCode(message)
            } else {
                throw new Error("Client OS cannot be handled")
            }

        } catch (e) {
            console.warn(`Failed to send message ${JSON.stringify(message)}`);
            if (android || ios) {
                throw new Error(`Message failed to connect to native bridging code with error ${e.toString()}`)
            }
        }
    }

}

export default MessangerBridge

