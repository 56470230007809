import POI from "../types/general/POI";
import {LngLat} from "mapbox-gl";
import MLatLng from "../types/general/MLatLng";

class POIUtils {

    static Mazemap: any;

    constructor(mazemap: any) {
        POIUtils.Mazemap = mazemap
    }

    static initialize(mazemap: any) {
        POIUtils.Mazemap = mazemap
    }

    static async getPOICoordinatesByID(poiID: string): Promise<LngLat> {
        const poiInfo = await POIUtils.getPOIInformation(poiID);
        return POIUtils.getPOICoordinates(poiInfo)
    }

    static getPOICoordinates(poi: POI): LngLat {
        return POIUtils.Mazemap.Util.getPoiLngLat(poi) as LngLat;
    }

    static async getPOIInformation(archibusId: string): Promise<POI> {
        const pois = await POIUtils.Mazemap.Data.getPois({identifier: archibusId, campuscollectiontag: 'default'});
        return Array.isArray(pois) && pois.length > 0 ? pois[0] : null;
    }

    static async getClosestPOI(latLng: MLatLng) {
        return POIUtils.Mazemap.Data.getPoiAt(latLng, latLng.z || 1);
    }

}

export default POIUtils