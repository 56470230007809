import NativeLink from "./NativeLink";
import MapMessage from "../../../types/map-callback/MapMessage";

const interfaceName = "monashapp";

class AndroidMessage implements NativeLink {

    callNativeCode(message: MapMessage<any>): void {
        console.log(`callNativeCode(${JSON.stringify(message)})`)
        this.exec(message.messageType, message);
    }

    exec(func: any, message: any) {
        // need to wrap in a function to preserve 'this'
        console.log(`exec(${func}, ${JSON.stringify(message)})`)
        //@ts-ignore
        const monashapp = window[interfaceName];
        monashapp[func](JSON.stringify(message));
    }


}


/*
*
* WHO'S MAINTAINING THE WEB APP
*
* User like Android develoepr (consume from mobile side)
*
* Consuming from an IOS side.
*
*
* Each reference get a POI data
*
* Note on asyncronous stuff
*
* Visible state of the map
*
* */

export default AndroidMessage

