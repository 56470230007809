import BaseImplementation from "../BaseImplementation";
import MapPosition from "../../contract/MapPosition";
import {CameraOptions, FlyToOptions, LngLatLike} from "mapbox-gl";

class MPosition extends BaseImplementation implements MapPosition {
    exportWindow(): void {
        // @ts-ignore
        window.flyTo = this.flyTo.bind(this)
        // @ts-ignore
        window.jumpTo = this.jumpTo.bind(this)
    }

    flyTo(latLng: LngLatLike, options?: FlyToOptions): void {
        const mergedOptions: FlyToOptions = {center: latLng, ...options};
        this.map.flyTo(mergedOptions)
    }

    jumpTo(latLng: LngLatLike, options?: CameraOptions): void {
        const mergedOptions: CameraOptions = {center: latLng, ...options};
        this.map.jumpTo(mergedOptions)
    }

    getZLevel(level: number): void {
        this.map.getZLevel()
    }

    setZLevel(level: number): void {
        this.map.setZLevel(level)
    }



}

export default MPosition