import BaseImplementation from "../BaseImplementation";
import MapZoom from "../../contract/MapZoom";
import {AnimationOptions} from "mapbox-gl";

class MZoom extends BaseImplementation implements MapZoom {

    exportWindow(): void {
        // @ts-ignore
        window.getZoomLevel = this.getZoomLevel.bind(this);
        // @ts-ignore
        window.zoomBy = this.zoomBy.bind(this);
        // @ts-ignore
        window.zoomTo = this.zoomTo.bind(this);
    }

    getZoomLevel(): number {
        return this.map.getZoom();
    }

    zoomBy(by: number, animationOptions?: AnimationOptions): void {
        this.map.zoomTo(this.getZoomLevel() + by, animationOptions)
    }

    /*zoomOut(by: number): void {
        this.map.zoomOut(by)
    }

    zoomIn(by: number): void {
        this.map.zoomIn(by)
    }*/

    zoomTo(zoomLevel: number, animationOptions?: AnimationOptions): void {
        this.map.zoomTo(zoomLevel, animationOptions)
    }
}

export default MZoom