import LoadObserver from "./LoadObserver";
import { Observable } from "rxjs";
import MapReady from "../types/observer-message-types/MapReady";
import MLatLng from "../types/general/MLatLng";
import LipiObserver from "./location-observer/LipiObserver";
import GeoObserver from "./location-observer/GeoObserver";
import LocationObserver from "./location-observer/LocationObserver";
import MLatLngInternal from "../types/general/MLatLngInternal";

class AllObservers {
  static loadObserver: Observable<MapReady>;
  static lipiObserver: Observable<MLatLngInternal>;
  static geoObserver: Observable<MLatLngInternal>;
  static locationObserver: Observable<MLatLng>;

  static initialize() {
    AllObservers.loadObserver = new LoadObserver().observer;
  }

  static initializeLocation() {
    AllObservers.lipiObserver = new LipiObserver(true).observer;
    AllObservers.geoObserver = new GeoObserver().observer;
    AllObservers.locationObserver = new LocationObserver().observer;
  }
}

export default AllObservers;
