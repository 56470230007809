import BaseImplementation from "./BaseImplementation";
import MapLocation from "../contract/MapLocation";
import DotMode from "../types/general/DotMode";
import AllObservers from "../observers/AllObservers";
import MLatLng from "../types/general/MLatLng";
import MapMessage from "../types/map-callback/MapMessage";
import MessageType from "../types/map-callback/MessageType";
import MessangerBridge from "./map-callback/native-link/MessangerBridge";

class MLocation extends BaseImplementation implements MapLocation {

    // TODO: typedefs
    locationDot: any;
    locationController: any;

    added: boolean = false;


    afterMapIsReady(): void {
        super.afterMapIsReady();
    }

    changeLocationControllerData(location: MLatLng) {

        if (!this.added) {
            this.added = true;

            // Add the controllers
            this.locationDot = new this.Mazemap.BlueDot({
                zLevel: 1,
                accuracyCircle: true
            }).setLngLat({lat: 0, lng: 0});

            this.locationDot.addTo(this.map);
            this.locationController =  new this.Mazemap.LocationController({
                blueDot: this.locationDot,
                map: this.map
            });
        }

        this.locationController.updateLocationData({
            lngLat: {lat: location.lat, lng: location.lng},
            zLevel: location.z,
            accuracy: location.accuracy || 0
        })
    }
    
    onLocationChanged(location: MLatLng): MapMessage<MLatLng> {
        const message : MapMessage<MLatLng> = {
            messageType: MessageType.MAP_LOCATION_CHANGED,
            message: location
        };
        MessangerBridge.sendMessage(message);
        return message;
    }

    startLocation(): void {
        AllObservers.initializeLocation();
        AllObservers.locationObserver.subscribe(locationUpdate => {
            this.changeLocationControllerData(locationUpdate)
            this.onLocationChanged(locationUpdate)
        })
    }


    exportWindow(): void {
        // @ts-ignore
        window.startLocation = this.startLocation.bind(this);
        // @ts-ignore
        window.setLocationDotMode = this.setLocationDotMode.bind(this);
    }

    setLocationDotMode(mode: DotMode): void {
        this.locationController.setState(mode);
    }

    setLocationDotVisibility(show: boolean): void {

    }

}

export default MLocation