import BaseObserver from "../BaseObserver";
import MLatLng from "../../types/general/MLatLng";
import {Observable} from "rxjs";
import LIPIResponse from "../../types/general/LIPIResponse";
import {LocationErrors} from "../../contract/errors/ActionErrors";
import MLatLngInternal from "../../types/general/MLatLngInternal";
import LocationSource from "../../types/general/LocationSource";

class LipiObserver extends BaseObserver<MLatLngInternal> {
    observer: Observable<MLatLngInternal>;
    previousEmittedValue?: MLatLng;

    constructor(sameValueEmit: boolean = false) {
        super();

        this.observer = Observable.create((subscribe: any) => {

            setInterval(async () => {

                // subscribe.next({lat: 3, lng: 3, z: 3, source: LocationSource.LIPI, timestamp: this.getTimestamp()});

                const response = await this.getLippiInformation();
                if (response.error) {
                    subscribe.error(LocationErrors.LIPI_ERROR)
                }

                const conversion = this.convertLipiToMLatLng(response);

                if (!conversion) {
                    subscribe.error(LocationErrors.NO_LOCATION_AVAILABLE)
                } else if (!this.sameAsPreviousEmittedValue(conversion) || sameValueEmit) {
                    subscribe.next(conversion);
                    this.previousEmittedValue = conversion
                }

            }, 10000)

        });

    }

    sameAsPreviousEmittedValue(newValue: MLatLng): boolean {
        const {z, lat, lng} = newValue;
        if (!this.previousEmittedValue) return false;
        try {
            return this.previousEmittedValue.z === z && this.previousEmittedValue.lat === lat
                && this.previousEmittedValue.lng === lng
        } catch (e) {
            return false
        }
    }

    convertLipiToMLatLng(lipiResponse: LIPIResponse): MLatLngInternal | undefined {
        if (lipiResponse.geoLongitude && lipiResponse.geoLatitude)
            return {
                z: lipiResponse.z,
                lat: lipiResponse.geoLatitude,
                lng: lipiResponse.geoLongitude,
                source: LocationSource.LIPI,
                timestamp: this.getTimestamp(),
                accuracy: 0
            };
        return undefined
    }

    async getLippiInformation(): Promise<LIPIResponse> {
        let error: Boolean;
        let res: Response;

        try {
            res = await (fetch("https://monash.mazepos.com/position").catch((e) => {throw new Error(e.toString())}));
            error = !res.ok;
            if (error) return {error: true, errorInfo: res.statusText};
        } catch (e) {
            return {error: true, errorInfo: "Failed Fetch"}
        }


        if (error) {
            return {error: true, errorInfo: res.statusText.toString()};
        } else {
            let jsonRes = {};
            if (!error) jsonRes = await res.json();
            // @ts-ignore
            jsonRes["z"] = Number(jsonRes["z"]);
            return {...jsonRes, error} as LIPIResponse
        }
    }
}

export default LipiObserver;