import MazemapOptions from "./types/maze-map-types/MazemapOptions";
import MazemapDefinition from "./types/maze-map-types/Mazemap";
import MazeInstance from "./single/MazeInstance";
import Bridge from "./contract/Bridge";
import MapActions from "./contract/MapActions";
import MActions from "./implementations/map-actions/MActions";
import MapZoom from "./contract/MapZoom";
import MZoom from "./implementations/map-zoom/MZoom";
import ViewState from "./types/map-actions/ViewState";
import AllObservers from "./observers/AllObservers";
import MapPosition from "./contract/MapPosition";
import MPosition from "./implementations/map-position/MPosition";
import MapAppActions from "./contract/MapAppActions";
import MAppActions from "./implementations/map-app-actions/MAppActions";
import POIUtils from "./single/POIUtils";
import MapMarkers from "./contract/MapMarkers";
import MMarkers from "./implementations/map-markers/MMarkers";
import MapRoutes from "./contract/MapRoutes";
import MRoutes from "./implementations/map-routes/MRoutes";
import MapCallback from "./contract/MapCallback";
import MCallback from "./implementations/map-callback/MCallback";
import MapLocation from "./contract/MapLocation";
import MLocation from "./implementations/MLocation";


const DEFAULT_MAP_OPTIONS: MazemapOptions = {
    zLevelControl: false,
    zLevelUpdater: true,
};


class MonashMap implements Bridge {

    // These won't be null in usage
    // ! used to prevent usage of // @ts-ignore to suppress
    mapActions!: MapActions;
    mapZoom!: MapZoom;
    mapPosition!: MapPosition;
    mapAppActions!: MapAppActions;
    mapMarkers!: MapMarkers;
    mapRoutes!: MapRoutes;
    mapCallback!: MapCallback;
    mapLocation!: MapLocation;


    static map: MazemapDefinition;
    static Mazemap = MazeInstance.Mazemap;

    // region private functions

    initMap(container: string, options?: MazemapOptions) {
        const mergedOptions: MazemapOptions = {...options, ...DEFAULT_MAP_OPTIONS};
        // @ts-ignore
        MonashMap.map = new MonashMap.Mazemap.Map({
            container,
            ...mergedOptions
        });

        // region observers and static initializers

        AllObservers.initialize();
        POIUtils.initialize(MonashMap.Mazemap);

        // endregion

        // region Bridge Implementation

        this.mapActions = new MActions();
        this.mapZoom = new MZoom();
        this.mapPosition = new MPosition();
        this.mapMarkers = new MMarkers();
        this.mapRoutes = new MRoutes();
        this.mapCallback = new MCallback();
        this.mapLocation = new MLocation();

        this.mapAppActions = new MAppActions(this.mapZoom, this.mapPosition, this.mapActions, this.mapMarkers, MonashMap.map);

        // endregion

        this.mapActions.setMapVisibleState(ViewState.INVISIBLE);

    }

    // endregion

    constructor(elementID: string, options?: MazemapOptions) {

        this.initMap(elementID, {campuses: "monashuni", zLevel: 1})

        // region Binding functions

        // endregion

    }
}


export default MonashMap
