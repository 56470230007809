import BaseObserver from "../BaseObserver";
import MLatLng from "../../types/general/MLatLng";
import {combineLatest, Observable} from "rxjs";
import AllObservers from "../AllObservers";
import MLatLngInternal from "../../types/general/MLatLngInternal";
import {startWith} from "rxjs/operators";

class LocationObserver extends BaseObserver<MLatLng> {
    observer: Observable<MLatLng>;

    static lastKnownLocation: MLatLng | undefined = undefined;

    /**
     * Could throw errors like
     * NO location lock
     * Outdated Location
     *
     * Simplified to undefined at the moment
     */
    static getLastLocation() : MLatLng | undefined { return this.lastKnownLocation }

    constructor() {
        super();

        this.observer = Observable.create((subscribe: any) => {

            /*
            * The logic applied here is following: 
            * 
            * Lippi     ~~~~~~C~~~~~~O~~~O~O~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            * GeoLO     ~~X~~X~~X~~XXXXX~~~X~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~X~~~~
            * 
            * Result    ~~X~~X?~O~~O?O~~~O~O~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~X~~~~
            *
            * ? = CX => C = z, X = lat, lng
            *
            * The ? means if lipi is incomplete (only z level available merge with geo to get coords)
            * It can also combine the latest z value from lipi for the ?
            * The LIPI z and coords override GPS
            * If LIPI hasn't been fetched in an interval where GPS has changed more significantly then switch
            *
            *
            * This is simplified NOW@
            * if LIPI -> show lipi
            * if GPS and LIPI is not offset old -> show lipi
            * if GPS and LIPI outdated -> show gps
            *
            * */

            combineLatest([AllObservers.geoObserver.pipe(startWith(null)),
                    AllObservers.lipiObserver.pipe(startWith(null))],
                (geoValue, lipiValue) => {
                    return this.combineGeoAndLippi(geoValue, lipiValue);

                }).subscribe((combined) => {
                if (combined) {
                    LocationObserver.lastKnownLocation = combined;
                    subscribe.next(combined as MLatLng)
                }
            }, console.warn);
        });

        this.observer.subscribe(locValue => {
            LocationObserver.lastKnownLocation = locValue
        })

    }

    combineGeoAndLippi(geo: MLatLngInternal | null, lippi: MLatLngInternal | null): MLatLng | null {

        if (!lippi && !geo) return null;
        if (!lippi && geo) return geo;
        if (!geo && lippi) return lippi;

        if (lippi && geo) {
            const lippiIsRecent = lippi.timestamp < geo.timestamp;
            const timeDiff = Math.abs(lippi.timestamp - geo.timestamp);
            const timeAllowance = 15 * 1000;

            if (lippiIsRecent) return lippi as MLatLng;
            else if (timeDiff > timeAllowance) return geo as MLatLng;
            else return lippi as MLatLng;
        }

        return null
    }

}

export default LocationObserver