import MazemapDefinition from "../types/maze-map-types/Mazemap";
import MonashMap from "../MonashMap";
import {fromEvent, Observable} from "rxjs";
import MapEvent from "../types/observer-message-types/MapEvent";


abstract class BaseObserver<T> {
    map: MazemapDefinition = MonashMap.map;
    Mazemap = MonashMap.Mazemap;

    fromMapEvent(event: MapEvent): Observable<T> {
        // @ts-ignore
        return fromEvent(this.map, event)
    }

    abstract observer: Observable<T>;

    getTimestamp(): number {
        return Date.now()
    }
}

export default BaseObserver

