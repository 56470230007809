// constants
const NON_PROD_URL = 'https://monash-digital-wayfinding-uat.appspot.com/splunk/s';
const PROD_URL = 'https://maps.monash.edu/splunk/s';

const TAG_LOCATION_ACTIVATION = 'da-2_blue_dot'; // current location is activated return lng/lat.

// API methods
const push = (source: string, tag: any, data?: any) => {
    console.log(`[BIData]:push for ${source}`, tag, data);

    const fetchBaseUrl = window.location.host === 'maps.mobile.monash' ? PROD_URL : NON_PROD_URL;
    console.log(`fetchBaseUrl: ${fetchBaseUrl}`);

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            source: source, // mobile-ios OR mobile-android
            ...data
        }),
    };

    fetch(`${fetchBaseUrl}/${tag}`, requestOptions)
        .then((response) => response.text())
        .then((result) => console.log('📈', result))
        .catch((error) => console.log('error', error));
};

// return module
const BIData = {
    // enums
    TAG_LOCATION_ACTIVATION,

    // methods
    push,
};

export default BIData;