import BaseImplementation from "../BaseImplementation";
import MapAppActions from "../../contract/MapAppActions";
import POIUtils from "../../single/POIUtils";
import MapZoom from "../../contract/MapZoom";
import MapPosition from "../../contract/MapPosition";
import MapActions from "../../contract/MapActions";
import ViewState from "../../types/map-actions/ViewState";
import MapMarkers from "../../contract/MapMarkers";
import mapboxgl, {MarkerOptions} from "mapbox-gl";
import MazeMarkerOptions from "../../types/general/MazeMarkerOptions";
import ExecutionStatus from "../../types/general/ExecutionStatus";
import MazemapDefinition from "../../types/maze-map-types/Mazemap";
import MonashMap from "../../MonashMap";
import BIData from "../../analytics";
import MActions from "../map-actions/MActions";

const TRACK_USER_LOCATION_STATUS = {
    STARTED: 'STARTED', // initiated but could be still waiting for device's location data
    IN_PROGRESS: 'IN_PROGRESS', // received first location data from device and keep tracking in progress
    END: 'END', // stopped tracking location
    ERROR: 'ERROR',
};

class MAppActions extends BaseImplementation implements MapAppActions {

    map: MazemapDefinition;

    zoom: MapZoom;
    position: MapPosition;
    actions: MapActions;
    markers: MapMarkers;

    floorBar: mapboxgl.Control;
    zoomControl: mapboxgl.Control;
    locationControl: mapboxgl.Control;

    DEFAULT_ZOOM: number;

    status: string;

    constructor(zoom: MapZoom, position: MapPosition, actions: MapActions, markers: MapMarkers, map: MazemapDefinition) {
        super();

        this.map = map;
        this.zoom = zoom;
        this.position = position;
        this.actions = actions;
        this.markers = markers;

        this.DEFAULT_ZOOM = 17.5;
        this.status = TRACK_USER_LOCATION_STATUS.END;

        this.floorBar = new MonashMap.Mazemap.ZLevelBarControl();
        this.zoomControl = new MonashMap.Mazemap.mapboxgl.NavigationControl();
        this.locationControl = new MonashMap.Mazemap.mapboxgl.GeolocateControl(
                {
                    positionOptions: {
                        enableHighAccuracy: true
                    },
                    trackUserLocation: true,
                    showUserLocation: true
                }
            );

      // setup control event callbacks
      this.locationControl.on('geolocate', (position: any) => {
        console.log('[geolocateCtrl]: geolocate: ', position);
        if (this.status === TRACK_USER_LOCATION_STATUS.STARTED) {
          BIData.push(MActions.source, BIData.TAG_LOCATION_ACTIVATION, {
            lng: position.coords.longitude,
            lat: position.coords.latitude,
            accuracy: position.coords.accuracy,
          });
          // update status to prevent tracking on further location update events
          this.status = TRACK_USER_LOCATION_STATUS.IN_PROGRESS;
        }
      });
      this.locationControl.on('error', (positionError: any) => {
        console.log('[geolocateCtrl]: error: ', positionError);
        this.status = TRACK_USER_LOCATION_STATUS.ERROR;
      });
      this.locationControl.on('trackuserlocationstart', () => {
        console.log('[geolocateCtrl]: trackuserlocationstart');
        this.status = TRACK_USER_LOCATION_STATUS.STARTED;
      });
      this.locationControl.on('trackuserlocationend', () => {
        console.log('[geolocateCtrl]: trackuserlocationend');
        this.status = TRACK_USER_LOCATION_STATUS.END;
      });
    }

    exportWindow(): void {
        // @ts-ignore
        window.snapToPOIOnClassClick = this.snapToPOIOnClassClick.bind(this)
        // @ts-ignore
        window.flyToPOI = this.flyToPOI.bind(this)
        // @ts-ignore
        window.jumpToPOI = this.jumpToPOI.bind(this)
        // @ts-ignore
        window.enableZoomControl = this.enableZoomControl.bind(this)
        // @ts-ignore
        window.disableZoomControl = this.disableZoomControl.bind(this)
        // @ts-ignore
        window.enableLocationControl = this.enableLocationControl.bind(this)
        // @ts-ignore
        window.disableLocationControl = this.disableLocationControl.bind(this)
        // @ts-ignore
        window.enableFloorControl = this.enableFloorControl.bind(this)
        // @ts-ignore
        window.disableFloorControl = this.disableFloorControl.bind(this)
    }

    async snapToPOIOnClassClick(poiID: string, markerOptions?: MazeMarkerOptions): Promise<ExecutionStatus> {
        this.zoom.zoomTo(this.DEFAULT_ZOOM, {animate: false});

        // Get POI Information
        const poi = await POIUtils.getPOIInformation(poiID);
        this.position.setZLevel(poi.properties.zLevel || 1);

        // Set center latLng
        const centerPoint = POIUtils.getPOICoordinates(poi);
        this.position.jumpTo(centerPoint);

        // Draw marker and polygon
        const mergedMarkerOptions: MarkerOptions = {color: "#006CAB", ...markerOptions};
        this.markers.addMarker(centerPoint, mergedMarkerOptions);
        this.markers.addPolygon(poi);

        if (markerOptions?.offset) {
            this.map.panBy(markerOptions.offset, {animate: false})
        }

        // Make map visible
        this.actions.setMapVisibleState(ViewState.VISIBLE);

        return {
            functionName: "snapToPOIOnClassClick",
            status: true
        }

    }

    async flyToPOI(poiID: string, options: mapboxgl.FlyToOptions = { zoom: this.DEFAULT_ZOOM }): Promise<void> {

        // Get POI Information
        const poi = await POIUtils.getPOIInformation(poiID);

        // Set default zoom if not specified
        if (options.zoom === undefined) {
            options.zoom = this.DEFAULT_ZOOM;
        }

        // Set center latLng
        const centerPoint = POIUtils.getPOICoordinates(poi);
        this.position.flyTo(centerPoint, options);
    }

    async jumpToPOI(poiID: string, options: mapboxgl.CameraOptions = { zoom: this.DEFAULT_ZOOM }): Promise<void> {

        // Get POI Information
        const poi = await POIUtils.getPOIInformation(poiID);

        // Set default zoom if not specified
        if (options.zoom === undefined) {
            options.zoom = this.DEFAULT_ZOOM;
        }

        // Set center latLng
        const centerPoint = POIUtils.getPOICoordinates(poi);
        this.position.jumpTo(centerPoint, options);
    }

    // region add/remove the zoom controls
    async enableZoomControl(position?: string): Promise<void> {
        // @ts-ignore
        this.map.addControl(this.zoomControl, position ? position : "bottom-right");
    }

    async disableZoomControl(): Promise<void> {
        this.map.removeControl(this.zoomControl);
    }

    // region add/remove the location controls
    async enableLocationControl(position?: string): Promise<void> {
        // @ts-ignore
        this.map.addControl(this.locationControl,position ? position : "bottom-right");
    }

    async disableLocationControl(): Promise<void> {
        this.map.removeControl(this.locationControl);
    }

    // region add/remove the floor controls
    async enableFloorControl(position?: string): Promise<void> {
        // @ts-ignore
        this.map.addControl(this.floorBar,position ? position : "bottom-left");
    }

    async disableFloorControl(): Promise<void> {
        this.map.removeControl(this.floorBar);
    }
}

export default MAppActions;